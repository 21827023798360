import { useState } from 'react';
import {
	Button,
	Card,
	TextField,
	Typography,
	CircularProgress
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStyles } from './LoginPage.styles';
import {
	checkValidEmailPassword,
	defaultValues,
	schema
} from './LoginPage.utils';
import {
	email as emailInEnv,
	password as passwordInEnv
} from '../../config/env.json';
import Logo from '../../assets/img/stageClipLogo.png';
import Snackbar from '../../components/Snackbar/Snackbar';
import { AddAlert } from '@material-ui/icons';

const LoginPage = () => {
	const styles = useStyles();
	const { push } = useHistory();
	const [isLoading, setLoading] = useState(false);
	const [snackbarConfig, setSnackbarConfig] = useState({
		open: false,
		message: '',
		color: 'success'
	});

	const snackBarClose = () => {
		setSnackbarConfig({ ...snackbarConfig, open: false });
	};

	const { register, handleSubmit, setValue, control, errors } = useForm({
		shouldUnregister: false,
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(schema)
	});

	const onSubmit = async (data) => {
		const { email, password } = data;
		setLoading(true);
		const isUserHaveAccess = await checkValidEmailPassword(email, password);
		if (isUserHaveAccess) {
			await window.localStorage.setItem('userIn', true);
			setSnackbarConfig({
				open: true,
				message: 'Login successful, redirecting...',
				icon: AddAlert,
				color: 'success'
			});
			setTimeout(() => {
				setLoading(false);
				push('/activities');
			}, 1000);
		} else {
			setSnackbarConfig({
				open: true,
				message: 'Login failed. Please check the credentials.',
				icon: AddAlert,
				color: 'danger'
			});
			setLoading(false);
		}
	};

	return (
		<>
			{isLoading ? (
				<div className={styles.loaderContainer}>
					<CircularProgress className={styles.loader} title='Loading....' />
				</div>
			) : (
				<form
					name='loginForm'
					className={styles.formContainer}
					onSubmit={handleSubmit(onSubmit)}
				>
					<Card className={styles.cardContainer}>
						<div className={styles.logoContainer}>
							<img src={Logo} alt='stageClip' />
						</div>
						<Typography variant='h5' className={styles.header}>
							Login
						</Typography>
						<div className={styles.fieldContainer}>
							<Typography variant='body1'>Email</Typography>
							<TextField
								inputRef={register}
								error={!!errors.email}
								helperText={errors?.email?.message}
								name='email'
								type='email'
								variant='outlined'
								className={styles.textFields}
							/>
						</div>
						<div className={styles.fieldContainer}>
							<Typography variant='body1'>Password</Typography>
							<TextField
								inputRef={register}
								error={!!errors.password}
								helperText={errors?.password?.message}
								name='password'
								type='password'
								variant='outlined'
								className={styles.textFields}
							/>
						</div>
						<div className={styles.fieldContainer}>
							<Button variant='contained' type='submit' className={styles.loginButton}>
								Login
							</Button>
						</div>
					</Card>
				</form>
			)}
			<Snackbar
				{...snackbarConfig}
				icon={AddAlert}
				close
				closeNotification={snackBarClose}
				place='tr'
			/>
		</>
	);
};

export default LoginPage;
